<template>
  <div class="details">
    <div class="backPage zcPage" @click="$router.go(-1)">返回</div>
    <heand :tagnum="tagnum"></heand>
    <div class="c-top" ref="c-top" v-if="tagnum != 0">
      <ul>
        <li
          :class="$route.name == 'favq' ? 'active' : ''"
          @click="$router.push({ name: 'favq' })"
        >
          骨干企业
        </li>
        <li
          :class="$route.name == 'chuangxin' ? 'active' : ''"
          @click="$router.push({ name: 'chuangxin' })"
        >
          创新资源
        </li>
        <li
          :class="$route.name == 'zaiti' ? 'active' : ''"
          @click="$router.push({ name: 'zaiti' })"
        >
          产业载体
        </li>
        <li
          :class="$route.name == 'zhecedetails' ? 'active' : ''"
          @click="$router.push({ name: 'zhecedetails' })"
        >
          产业政策
        </li>
      </ul>
    </div>
    <div class="details-box">
      <div class="box">
        <h2>{{ details.name }}</h2>
        <p class="deta">
          <span>发文机构:{{ details.org }}</span>
          <span>发文字号:{{ details.post_num }}</span>
          <span>所属行业:{{ details.industry }}</span>
          <span>发布地区:{{ details.district }}</span>
        </p>
        <p class="deta">
          <span>发布时间:{{ details.pubdate }}</span>
          <!-- <span>政策类型:{{ details.category }}</span> -->
          <span>
            原文链接:
            <a :href="details.url" target="_blank">{{ details.url }}</a>
          </span>
        </p>
        <!-- <div class="word" v-html="details.body"></div> -->
        <!-- <pre class="word">{{details.body}}</pre> -->
        <iframe
          style="margin-top:30px;"
          :src="details.file"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { policydetail, getpolicyDetailsdis } from "@/api/index";
export default {
  name: "zhecedetails",
  data() {
    return {
      tagnum: 1,
      details: [],
    };
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.id = this.$route.query.id;
    this.tagnum = this.$route.query.dis;
    if (this.tagnum == 0) {
      this.getpolicyDetailsdis();
    } else {
      this.getpolicydetail();
    }
  },
  methods: {
    async getpolicydetail() {
      const res = await policydetail({
        id: this.id,
      });
      this.details = res;
    },
    async getpolicyDetailsdis() {
      const res = await getpolicyDetailsdis({
        id: this.id,
      });
      this.details = res;
    },
  },
};
</script>

<style lang="less" scoped>
iframe {
  width: 80%;
  height: 1400px;
  html,
  body {
    height: 100%;
  }
  padding-bottom: 30;
}
.details {
  width: 100%;
  padding-bottom: 30px;
  background: rgb(243, 243, 243);
  .details-box {
    margin-top: 10px;
    padding: 0 30px;
    .box {
      padding-top: 45px;
      width: 100%;
      background-color: #fff;
      min-height: 1300px;
      .deta {
        width: 80%;
        min-height: 42px;
        line-height: 42px;
        background: #f2f4f7;
        margin: 0 auto;
        // margin-top: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 20px;
        // margin-bottom: 30px;
      }
      .word {
        width: 80%;
        line-height: 30px;
        margin: 0 auto;
        margin-top: 30px;
        text-align: left;
        padding-bottom: 30px;
      }
    }
  }
}
.c-top {
  width: 100%;
  height: 72px;
  background: #fff;
  li {
    display: block;
    width: 100px;
    height: 38px;
    background: #eee;
    margin-top: 19px;
    border-radius: 6px;
    margin-right: 19px;
    font-size: 17px;
    font-weight: bold;
    color: #666666;
    text-align: center;
    line-height: 38px;
    float: left;
    cursor: pointer;
    &:nth-child(1) {
      margin-left: 52px;
    }
    &.active {
      background: rgb(254, 147, 34);
      color: #ffffff;
    }
  }
  small {
    display: inline-block;
    line-height: 31px;
    text-align: center;
    width: 88px;
    height: 31px;
    background: #fe6a00;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    margin-right: 40px;
    cursor: pointer;
  }
}
.zcPage {
  top: 205px;
  right: 140px;
}
@media (max-width: 1440px) {
  .zcPage {
    top: 190px;
    right: 75px;
  }
}
</style>
